<template>
  <Route :to="{ name: dashboardRoute }">
    <div :class="statusClass">
      <img src="@/assets/images/user-dashboard.png" alt="status-icon" />
    </div>
  </Route>
</template>
<script>
import { mapState } from 'vuex'
import {
  Module,
  CASINO_INFO,
  clubLevel,
  CLUB_STATUS_ICON,
  RouteName,
} from '@/constants'
import * as CasinoInfo from '@/models/getters/casinoInfo'

export default {
  name: CLUB_STATUS_ICON,
  components: {
    Route: () => import('@/components/Route'),
  },
  props: {
    level: {
      type: [Number, null],
      default: null,
    },
  },
  computed: {
    ...mapState(Module.CASINO_INFO, [CASINO_INFO]),
    defaultLevel() {
      return CasinoInfo.getCardLevel(this.CASINO_INFO)
    },
    cardLevel() {
      return this.level || clubLevel[this.defaultLevel]
    },
    statusClass() {
      return `csn-csi csn-csi-${this.cardLevel}`
    },
    dashboardRoute: () => RouteName.DASHBOARD,
  },
}
</script>
